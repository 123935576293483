import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Grid, Box, Typography, ListItem, List, ListItemText } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from '@mui/material/IconButton';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlaceIcon from '@mui/icons-material/Place';
import { Link, useLocation } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
import { colors } from '../../theme/colors';

const Footer = () => {
  const location = useLocation();
  const { isMobile } = useDevice();

  const [scrollValue, setScrollValue] = useState();

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollValue = window.scrollY;
      setScrollValue(scrollValue);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // console.log('data :', scrollValue);

  const footerItems = [
    {
      itemName: 'Home',
      link: '/home',
    },
    {
      itemName: 'Products',
      link: '/products',
    },

    {
      itemName: 'Estimate',
      link: '/estimate',
    },
    {
      itemName: 'About',
      link: '/about',
    },
  ];

  const footerItemsMobile = [
    {
      itemName: 'Home',
      pathName: '/home',
      activeImage: '/assets/images/Footer/Orrange_home.png',
      inActiveImage: '/assets/images/Footer/Grey_home.png',
      alt: '1',
    },
    {
      itemName: 'Products',
      pathName: '/products',
      activeImage: '/assets/images/Footer/Orrange_product.png',
      inActiveImage: '/assets/images/Footer/Grey_product.png',
      alt: '2',
    },
    {
      itemName: 'Estimate',
      pathName: '/estimate',
      activeImage: '/assets/images/Footer/Orrange_estimate.png',
      inActiveImage: '/assets/images/Footer/Grey_estimate.png',
      alt: '3',
    },
    {
      itemName: 'About',
      pathName: '/about',
      activeImage: '/assets/images/Footer/Orrange_about.png',
      inActiveImage: '/assets/images/Footer/Grey_about.png',
      alt: '4',
    },
  ];

  return (
    <>
      <Grid sx={{ backgroundColor: colors.secondary, p: { lg: 11, xs: 11 }, px: 45 }}>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: `${isMobile && location?.pathname !== '/booking' && '110px'}` }}
          >
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img
                  className="footerlogo"
                  src={`/assets/images/Footer/logosmall.png`}
                  alt="Logo"
                  style={{ height: '96px', width: '156px' }}
                />
              </Box>
            </Grid>
            <Grid
              alignItems={'center'}
              justifyContent={'center'}
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              <ul style={{ listStyleType: 'none', padding: 0, lineHeight: '30px' }}>
                <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                  {footerItems?.map((item, key) => (
                    <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                      <ListItem>
                        <ListItemText sx={{ color: 'gray' }} primary={item?.itemName} />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 22,
                    fontWeight: 800,
                    color: colors.primary,
                    mb: 2,
                    pt: { xs: 9, sm: 0 },
                    textAlign: { xs: 'center', sm: 'start' },
                  }}
                >
                  Contact Us!
                </Typography>
                <Box sx={{ display: 'flex', pb: 2, color: 'gray' }}>
                  <LocalPhoneIcon sx={{ marginRight: 1 }} /> <Typography>+971 56 269 6727</Typography>
                </Box>
                <Box sx={{ display: 'flex', pb: 2, color: 'gray' }}>
                  <PlaceIcon sx={{ marginRight: 1 }} /> <Typography>Dubai, UAE</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                  {/* <InstagramIcon sx={{ marginRight: 1 }} /> */}
                  <a
                    href="http://www.instagram.com/zealcurtains"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <InstagramIcon
                      sx={{
                        marginRight: 1,
                        '&:hover': {
                          color: '#a1a0a0',
                        },
                      }}
                    />
                  </a>
                  <a
                    href="http://www.facebook.com/zealcurtains"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        marginRight: 1,
                        '&:hover': {
                          color: '#a1a0a0',
                        },
                      }}
                    />
                  </a>
                  <TwitterIcon sx={{ marginRight: 1 }} />
                  <LinkedInIcon sx={{ marginRight: 1 }} />
                  <YouTubeIcon />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* ornge section */}
      {location?.pathname !== '/booking' && (
        <Grid
          className={`floating-card ${scrollValue >= 1350 ? 'active' : ''}`}
          sx={{
            backgroundColor: colors.primary,
            borderRadius: '5px 5px 0px 0px',
            padding: '12px',
            display: { sx: 'flex', sm: 'none' },
            position: 'fixed',
            bottom: '76px',
            width: '100%',
          }}
        >
          <Link to={'/booking'} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
              <IconButton sx={{ marginRight: 1 }}>
                <img src={`/assets/images/Header/icon.png`} alt=" 1" style={{ width: '16px', height: '18px' }} />
              </IconButton>
              <Typography className="click" variant="body1" sx={{ fontSize: '16px', fontWeight: '300' }}>
                Click here to {''}
                <Typography
                  className="book"
                  component="span"
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#fff', fontSize: '16px' }}
                >
                  Book A Free Home Visit
                </Typography>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              className="select"
              sx={{ color: '#fff', fontWeight: '200', fontSize: '12px', pl: 5.5 }}
            >
              Select your convenient time slot in the next step.
            </Typography>
          </Link>
        </Grid>
      )}
      {/* white section */}
      <Grid
        container
        spacing={1}
        sx={{
          padding: '1rem 1rem',
          display: { sx: 'flex', sm: 'none' },
          flexDirection: { sx: 'row' },
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'white',
        }}
      >
        {footerItemsMobile?.map((item, key) => (
          <Grid key={key} item xs={3} sm={6} md={3} lg={3}>
            <Link to={item?.pathName} style={{ textDecoration: 'none' }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column' }, alignItems: 'center' }}>
                {location?.pathname === item?.pathName ? (
                  <img src={item?.activeImage} alt={item?.alt} style={{ width: '16px', height: '18px' }} />
                ) : (
                  <img src={item?.inActiveImage} alt={item?.alt} style={{ width: '16px', height: '18px' }} />
                )}
                <Typography sx={{ textAlign: 'center', color: 'black', fontSize: '12px' }}>{item?.itemName}</Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Footer;
