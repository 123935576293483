import React, { useEffect, useState } from 'react';
import { CardMedia, Container, Typography, Card, CardContent, Button, Box, Stack, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Banner from '../segment/@common/Banner/banner';
import { colors } from '../theme/colors';
import { useDevice } from '../hooks/useResponsive';
import { curtainTypesData } from '../mock/curtains';
import EstimateCard from '../segment/Products/Estimate';
// import { styledCurtains } from './Home';
// import
export default function EstimatePage() {
  const { isMobile } = useDevice();
  const location = useLocation();

  const locationData = location?.state;

  const [selectedCurtain, setSelectedCurtain] = useState(curtainTypesData[0]);

  const handleCurtainTypeChange = (item) => {
    setSelectedCurtain(item);
  };

  useEffect(() => {
    if (locationData) {
      const currentItem = curtainTypesData?.filter((i) => i.id === locationData);
      setSelectedCurtain(currentItem[0]);
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });
    } else {
      setSelectedCurtain(curtainTypesData[0]);
    }
  }, [locationData]);

  return (
    <>
      {isMobile ? (
        <Box>
          <img src={'/assets/images/Estimate/banner2.png'} alt="" />
        </Box>
      ) : (
        <Box>
          <img src={'/assets/images/Estimate/banner.png'} alt="" />
        </Box>
      )}
      <Box sx={{ paddingTop: isMobile ? 0 : '20px', paddingBottom: isMobile ? 0 : '20px' }}>
        <Container
          className="EstimateContainer"
          maxWidth="lg"
          sx={{ paddingTop: isMobile ? 0 : '25px', paddingBottom: '25px', px: isMobile ? 0 : '' }}
        >
          <Stack
            mt={isMobile && -10}
            flexWrap={'wrap'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Card
              sx={{
                width: '970px',
                mx: 4,
                my: 3,
                borderRadius: 0,
                boxShadow: isMobile
                  ? '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)'
                  : 'none',
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  color: colors.primary,
                  fontWeight: '800',
                  fontSize: isMobile ? '14px' : '24px',
                  marginTop: isMobile ? '2rem' : '0px',
                  textAlign: 'center',
                }}
                noWrap
                className="boldfont"
              >
                Select your Product Type ↓
              </Typography>
              <CardContent sx={{ textAlign: 'center', p: 4 }}>
                <Grid container spacing={3}>
                  {curtainTypesData?.map((card) => (
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      key={card.id}
                      sx={{
                        position: 'relative',
                        border: selectedCurtain?.id === card.id ? '1px solid #ec2927' : '',
                        borderRadius: '13px',
                        width:"25rem",
                        cursor:"pointer"
                        }}
                      className="estimate-item-card"
                      onClick={() => handleCurtainTypeChange(card)}

                    >
                      {selectedCurtain?.id === card.id ? (
                        <Box
                        sx={{position:"absolute",right:"6px",top:"6px"}}
                        >
                          <img
                            src={'/assets/images/Estimate/tick.png'}
                            alt="Tick"
                            style={{ width: '30px', height: '30px' }}
                          />
                        </Box>
                      ) : null}
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CardMedia
                          component="div"
                          sx={{
                            position: 'relative',
                            width: isMobile ? '120px' : '150px',
                            height: isMobile ? '120px' : '150px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            marginBottom: '0.5rem',
                          }}

                        >
                          {/* {selectedCurtain?.id === card.id && (
                            <>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#EF7923',
                                  opacity: 0.3,
                                }}
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              >
                                <img
                                  src={'/assets/images/Estimate/tick.png'}
                                  alt="Tick"
                                  style={{ width: '30px', height: '30px' }}
                                />
                              </div>
                            </>
                          )} */}
                          <img
                            src={card.logoImage ? card.logoImage : '/assets/images/noImage/noImage.svg'}
                            alt={card.alt}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </CardMedia>
                        <Typography
                          gutterBottom
                          sx={{
                            color: colors.primary,
                            fontSize: isMobile ? '11px' : '15px',
                            fontWeight: '800',
                            whiteSpace: 'pre-wrap',
                          }}
                          noWrap
                        >
                          {card.content}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <EstimateCard
                price={selectedCurtain?.price}
                containerSx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  component={Link}
                  to="/booking"
                  className="zeal-btn-solid"
                  sx={{
                    borderRadius: 0,
                    fontSize: isMobile ? '15px' : '30px',
                    width: isMobile ? '100%' : '75%',

                    height: '60px',
                    fontWeight: 600,
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    const { title } = selectedCurtain || {};
                    localStorage.setItem('title', title);
                  }}
                >
                  <Box mr={3}>
                    <img
                      src={'/assets/images/Estimate/bigicon.png'}
                      alt="icons"
                      style={{ width: '25px', height: '30px' }}
                    />
                  </Box>{' '}
                  Book A Free Home Visit
                </Button>
              </Box>
            </Card>
          </Stack>
        </Container>
      </Box>

      <Banner addMargin />
    </>
  );
}
