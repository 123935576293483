// {
//   id: 1,
//   logoImage: '/assets/images/Estimate/interior.png',
//   bannerImage: '/assets/images/Home/interior.png',
//   alt: 'sun white sheer curtain',
//   content: 'SUN WHITE SHEER\nCURTAIN',
//   price: 150,
//   category: 'sheer',
//   title: 'SUN WHITE SHEER CURTAIN',
//   theme: 'left-align',
//   highlight: 'I need Privacy with a Subtle Glow!',
//   description:
//     'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
// },
// {
//   id: 2,
//   logoImage: '/assets/images/Estimate/sofa.jpg',
//   bannerImage: '/assets/images/Home/nightblackout.jpg',
//   alt: 'night blackout curtain',
//   content: 'NIGHT BLACKOUT\nCURTAIN',
//   price: 200,
//   category: 'blackout',
//   title: 'NIGHT BLACKOUT CURTAIN',
//   theme: 'right-align',
//   highlight: 'Immerse Yourself in Dream Darkness!',
//   description:
//     "Our Blackout Curtains are meticulously crafted to block out sunlight and external distractions, ensuring a restful and peaceful ambiance for a good night's sleep. Enjoy privacy, enhance your home experience.",
// },
// {
//   id: 3,
//   logoImage: '/assets/images/Estimate/bed.png',
//   bannerImage: '/assets/images/Home/blackout.jpg',
//   alt: 'blackout sheer combined',
//   content: 'BLACKOUT SHEER\nCOMBINED',
//   price: 320,
//   category: 'sheerBlackout',
//   title: 'BLACKOUT & SHEER COMBINED ',
//   theme: 'left-align',
//   highlight: 'Switch the Best of Both Worlds!',
//   description:
//     'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
// },
// {
//   id: 4,
//   logoImage: '/assets/images/Estimate/chair.png',
//   bannerImage: '/assets/images/Home/roller.jpg',
//   alt: 'roller blind curtain',
//   content: 'ROLLER BLIND\nCURTAIN',
//   price: 120,
//   category: 'blinds',
//   title: 'ROLLER BLIND CURTAIN',
//   theme: 'right-align',
//   highlight: 'Embrace Minimalism Sleek & Stylish!',
//   description:
//     'Our Roller Blinds offer a sleek and contemporary solution for light control and privacy. Enjoy a clutter-free window with these space-saving blinds that effortlessly blend into any interior, adding a touch of modern sophistication.',
// },

const curtainTypesData = [
  {
    id: 1,
    logoImage: '/assets/images/Estimate/American-Sheer-curtain.jpg',
    bannerImage: '/assets/images/Home/American-Sheer-curtain.jpg',
    alt: 'american sheer curtain',
    content: 'AMERICAN SHEER\nCURTAIN',
    price: 90,
    category: 'sheer',
    title: 'AMERICAN SHEER CURTAIN',
    theme: 'left-align',
    highlight: 'Privacy with a Soft, Elegant Glow!',
    description:
      'These American Sheer Curtains provide a soft, elegant solution for light control and privacy. With their airy fabric, they allow natural light to filter through while maintaining a sense of openness. These versatile curtains add a refined, modern touch to any space, effortlessly complementing both contemporary and classic interiors.',
  },
  {
    id: 2,
    logoImage: '/assets/images/Estimate/American-Blackout&Sheer-Curtain.jpeg',
    bannerImage: '/assets/images/Home/American-Blackout&Sheer-Curtain.jpeg',
    alt: 'American Blackout & Sheer Curtain',
    content: 'AMERICAN BLACKOUT&SHEER\nCURTAIN',
    price: 180,
    category: 'blackoutSheer',
    title: 'AMERICAN BLACKOUT&SHEER CURTAIN',
    theme: 'right-align',
    highlight: 'Seamless Light Control, Anytime!',
    description:
      'Enjoy the perfect balance of light and privacy with American Blackout & Sheer Curtains. Let soft, natural light filter through the sheers by day, then easily block out light and noise with the blackout layers for complete privacy and a restful atmosphere at night. Stylish, functional, and versatile for any room!',
  },
  {
    id: 3,
    logoImage: '/assets/images/Estimate/Wave-Sheer-Curtain.jpg',
    bannerImage: '/assets/images/Home/Wave-Sheer-Curtain.jpg',
    alt: 'Wave sheer curtain',
    content: 'WAVE SHEER\nCURTAIN',
    price: 110,
    category: 'sheer',
    title: 'WAVE SHEER CURTAIN',
    theme: 'left-align',
    highlight: 'Graceful Waves, Effortless Elegance!',
    description:
      'Want to bring effortless elegance to your home? Wave Sheer Curtains are the ideal solution. Their graceful, flowing design adds a soft, modern touch to any room while allowing natural light to softly filter through. Perfect for creating a relaxed, airy ambiance, they provide just the right balance of privacy and light. Whether you’re dressing up your living space or bedroom, Wave Sheers add a refined, sophisticated look that complements any décor.',
  },
  {
    id: 4,
    logoImage: '/assets/images/Estimate/Roman-Blinds.jpeg',
    bannerImage: '/assets/images/Home/Roman-Blinds.jpeg',
    alt: 'Roman Blinds',
    content: 'ROMAN\nBLINDS',
    price: 150,
    category: 'blinds',
    title: 'ROMAN BLINDS',
    theme: 'right-align',
    highlight: 'Classic Charm, Effortless Elegance!',
    description:
      'Transform your space with the sophisticated charm of Roman Blinds. Perfect for adding a touch of luxury and warmth, these elegant window treatments not only enhance your décor but also provide exceptional light control and privacy. Whether you’re looking to create a cozy retreat or a stylish statement, Roman Blinds are the perfect blend of beauty and functionality.',
  },
  {
    id: 5,
    logoImage: '/assets/images/Estimate/Rollar-blinds.jpg',
    bannerImage: '/assets/images/Home/Rollar-blinds.jpg',
    alt: 'Roller Blinds',
    content: 'ROLLER\nBLINDS',
    price: 100,
    category: 'blinds',
    title: 'ROLLER BLINDS',
    theme: 'left-align',
    highlight: 'Effortless Style, Perfect Privacy!',
    description:
      'Roller Blinds are the perfect blend of form and function. Offering sleek lines and easy operation, they provide excellent light control and privacy while enhancing your space with a modern, minimalist touch. Effortlessly chic and practical, they fit seamlessly into any room.',
  },
  {
    id: 6,
    logoImage: '/assets/images/Estimate/Vertical-Blinds.jpg',
    bannerImage: '/assets/images/Home/Vertical-Blinds.jpg',
    alt: 'Vertical Blinds',
    content: ' VERTICAL\nBLINDS',
    price: 250,
    category: 'blinds',
    title: ' VERTICAL BLINDS',
    theme: 'right-align',
    highlight: 'Maximize Light, Minimize Clutter!',
    description:
      'Add a touch of modern elegance to your home with vertical blinds! Ideal for wide windows or sliding doors, they offer a smart and stylish way to control light and privacy. With their sleek design and easy-to-adjust slats, vertical blinds bring both function and flair to any space—effortlessly enhancing your room’s look and feel.',
  },
  {
    id: 7,
    logoImage: '/assets/images/Estimate/American-Blackout-Curtain.jpeg',
    bannerImage: '/assets/images/Home/American-Blackout-Curtain.jpeg',
    alt: 'American blackout Curtain',
    content: 'AMERICAN BLACKOUT\nCURTAIN',
    price: 130,
    category: 'blackout',
    title: 'AMERICAN BLACKOUT CURTAIN',
    theme: 'left-align',
    highlight: 'Block the Light, Embrace the Comfort!',
    description:
      'Looking for complete darkness and privacy? American Blackout Curtains are exactly what you need. They block out all light, making your space perfect for restful sleep, movie nights, or just some quiet time. Plus, they help keep your room cooler in the summer and warmer in the winter, adding comfort and energy efficiency to your home.',
  },

  {
    id: 8,
    logoImage: '/assets/images/Estimate/Wave-Blackout-Curtain.jpeg',
    bannerImage: '/assets/images/Home/Wave-Blackout-Curtain.jpeg',
    alt: 'wave blackout curtain',
    content: 'WAVE BLACKOUT\nCURTAIN',
    price: 160,
    category: 'blackout',
    title: 'WAVE BLACKOUT CURTAIN',
    theme: 'right-align',
    highlight: 'Wave Elegance, Absolute Darkness!',
    description:
      'Need total darkness and privacy with a touch of style? Wave Blackout Curtains are the perfect solution. Their chic wave design not only adds elegance to your room but also blocks out all light, ensuring a peaceful sleep or a perfect movie night. Plus, they help maintain your room’s temperature—cooler in summer, warmer in winter—while enhancing your space’s energy efficiency.',
  },

  {
    id: 9,
    logoImage: '/assets/images/Estimate/Wave-Blackout&Sheer-Curtain.jpg',
    bannerImage: '/assets/images/Home/Wave-Blackout&Sheer-Curtain.jpg',
    alt: 'Wave - Blackout & Sheer Curtain',
    content: 'WAVE BLACKOUT&SHEER\nCURTAIN',
    price: 220,
    category: 'blackoutSheer',
    title: 'WAVE BLACKOUT&SHEER CURTAIN',
    theme: 'left-align',
    highlight: 'Sculpted Waves, Seamless Light Control!',
    description:
      'Want the perfect mix of light and privacy? Wave Blackout & Sheer Curtains have got you covered. The sheer fabric lets in just the right amount of daylight, while the blackout layer keeps your space dark and private when you need it. Plus, the chic wave design adds a stylish touch to any room. It’s the ideal solution for all your light and privacy needs, anytime!',
  },

  {
    id: 10,
    logoImage: '/assets/images/Estimate/Venetien-Blinds.jpeg',
    bannerImage: '/assets/images/Home/Venetien-Blinds.jpeg',
    alt: 'Venetien Blinds',
    content: 'VENETIEN\nBLINDS',
    price: 90,
    category: 'blinds',
    title: 'VENETIEN BLINDS',
    theme: 'right-align',
    highlight: 'Timeless Style, Ultimate Control!',
    description:
      'Elevate your space with the classic elegance of Venetian Blinds. Their adjustable slats offer precise control over light and privacy, while their sleek, timeless design adds a touch of sophistication to any room. Perfect for creating a modern yet functional look, Venetian Blinds are the ultimate choice for style and versatility.',
  },
];

export { curtainTypesData };
